import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DrillDownService {
  api: string;
  constructor(private httpClient: HttpClient) {
    this.api = environment.API_URL;
  }

  fetchSelectedDateRangeData(dateRange: any): Observable<any> {
    return this.httpClient.post<any>(
      this.api + 'fetch-selected-date-range-data',
      dateRange
    );
  }

  getSalesMarketingData(startAt: any, endAt: any): Observable<any> {
    return this.httpClient.post<any>(this.api + 'get-sales-marketing-data', {startAt, endAt});
  }

  getOperationData(startAt: any, endAt: any): Observable<any> {
    return this.httpClient.post<any>(this.api + 'get-operation-data', {startAt, endAt});
  }

  getFinancialData(startAt: any, endAt: any): Observable<any> {
    return this.httpClient.post<any>(this.api + 'get-financial-data', {startAt, endAt});
  }

  getGraphData(graphType: string, startAt: any, endAt: any): Observable<any> {
    return this.httpClient.post<any>(this.api + 'get-graph-data', {graphType, startAt, endAt});
  }
}
