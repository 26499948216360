import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { User } from '@models/user';
import { TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';
import dayjs from 'dayjs/esm';
import { ApiResponse } from '@models/api.response';
import { BillingApiResponse } from '@app/types/billing.api.response';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  api: string;
  private userDetails = new BehaviorSubject<any>(null);
  getUser = this.userDetails.asObservable();
  private dateRange = new BehaviorSubject<TimePeriod>({ startDate: dayjs().startOf('year'), endDate: dayjs() });
  getSelectedDateRange = this.dateRange.asObservable();
  private fetchState = new BehaviorSubject<string>('pending');
  getFetchState = this.fetchState.asObservable();
  private reloadUserDetails$ = new Subject<boolean>();
  constructor(
    private httpClient: HttpClient
  ) {
    this.api = environment.API_URL;
 }

  updateSelectedDateRange(dateRange: TimePeriod): void {
    this.dateRange.next(dateRange);
  }

  updateFetchedState(state: string): void {
    this.fetchState.next(state);
  }

  getUserDetails(): Observable<any> {
    return this.httpClient.get<any>(this.api + 'userDetails');
  }

  getCountries(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(this.api + 'get-countries');
  }

  changeUserDetails(user: User): void {
    this.userDetails.next(user);
  }

  getUserIntegrations(): Observable<any> {
    return this.httpClient.get<any>(this.api + 'integrations');
  }

  saveAccounts(selectedAccounts: any): Observable<any> {
    return this.httpClient.post<any>(this.api + 'save-marketing-accounts', {selectedAccounts});
  }

  getAccounts(): Observable<any> {
    return this.httpClient.get<any>(this.api + 'get-marketing-accounts');
  }

  updateProfile(data: { firstName: string, lastName: string, companyName: string, phone: string, country: string}): Observable<any> {
    return this.httpClient.post<any>(this.api + 'update-profile-data', data);
  }

  getSyncLogs(request: any): Observable<any> {
    return this.httpClient.post<any>(this.api + 'get-sync-logs', request);
  }

  reloadUserDetail(flag: boolean): void {
    this.reloadUserDetails$.next(flag);
  }

  getReloadUserDetailsObservable(): Observable<any> {
    return this.reloadUserDetails$.asObservable();
  }

  getUserLastSelectedDateRange(): Observable<any> {
    return this.httpClient.get<any>(this.api + 'get-last-selected-date-range');
  }

  updateUserLastSelectedDateRange(rateRange: TimePeriod): Observable<any> {
    return this.httpClient.post<any>(this.api + 'update-last-selected-date-range', rateRange);
  }

  updateProfileImage(userAvatar: any): Observable<any> {
    return this.httpClient.post<any>(this.api + 'update-profile-image', { userAvatar });
  }

  getPricingPlansList(): Observable<any> {
    return this.httpClient.post<any>(this.api + 'get-plans-list', {  });
  }

  getBillingDetail(): Observable<BillingApiResponse> {
    return this.httpClient.post<BillingApiResponse>(this.api + 'get-billing-details', {  });
  }

  updateCreditCard(): Observable<BillingApiResponse> {
    return this.httpClient.post<BillingApiResponse>(this.api + 'update-credit-card', {  });
  }

  updateCreditCardDetails(sessionId: string): Observable<BillingApiResponse> {
    return this.httpClient.post<BillingApiResponse>(this.api + 'update-credit-card-details', { sessionId });
  }

  pauseSubscription(): Observable<BillingApiResponse>  {
    return this.httpClient.post<BillingApiResponse>(this.api + 'pause-subscription', { });
  }

  resumeSubscription(): Observable<BillingApiResponse>  {
    return this.httpClient.post<BillingApiResponse>(this.api + 'resume-subscription', { });
  }

  cancelSubscription(requestBody: {leavingReason: string, improvement: string}): Observable<BillingApiResponse>  {
    return this.httpClient.post<BillingApiResponse>(this.api + 'cancel-subscription', requestBody);
  }

}